import { useState, useEffect } from "react";

/**
 * Hook that returns true when element exists (and/or has children)
 *
 * elementId <string>          - Id of the HTML element to watch
 * detectChildren <boolean>    - Flag that shows whether to watch for child node presence
 * onNodesAdded <function>     - Gets called when new nodes were added
 * stopObservingAfter <number> - Timeout after which the observer disconnects - miliseconds
 */
function useDetectElementLoaded({
  elementID,
  elementClass,
  detectChildren,
  onNodesAdded,
  stopObservingAfter,
}) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0 && onNodesAdded) {
          onNodesAdded();
        }
      });

      let found;

      if (elementID) {
        const element = document.getElementById(elementID);
        found = element && (!detectChildren || (detectChildren && element.hasChildNodes()));
      } else {
        const elements = document.getElementsByClassName(elementClass);
        found = elements.length > 0;
      }

      if (found) {
        setLoaded(true);
      }
    });

    const target = document.querySelector("body");
    observer.observe(target, { childList: true });

    // Stop observing dom changes after stopObservingAfter
    if (stopObservingAfter) {
      setTimeout(() => observer.disconnect(), stopObservingAfter);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementID, detectChildren, onNodesAdded, stopObservingAfter]);

  return loaded;
}

export default useDetectElementLoaded;
